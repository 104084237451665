define("shared/components/cluster-driver/driver-rke/component", ["exports", "shared/mixins/cluster-driver", "shared/utils/parse-version", "shared/utils/util", "@rancher/ember-api-store/utils/validate", "shared/utils/constants", "js-yaml", "shared/components/cluster-driver/driver-rke/template", "ui/components/input-text-file/component", "shared/components/cru-cloud-provider/cloud-provider-info", "moment", "shared/mixins/manage-labels", "semver", "ember-deep-set"], function (_exports, _clusterDriver, _parseVersion, _util, _validate, _constants, _jsYaml, _template, _component, _cloudProviderInfo, _moment, _manageLabels, _semver, _emberDeepSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var EXCLUDED_KEYS = ['name'];
  var EXCLUDED_CHILDREN_KEYS = ['extra_args', 'nodelocal', 'dns'];

  function camelToUnderline(str) {
    var split = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    str = str || '';

    if (str.indexOf('-') > -1 || str.endsWith('CloudProvider')) {
      return str;
    } else if (split) {
      return (str || '').dasherize().split('-').join('_');
    } else {
      return (str || '').dasherize();
    }
  }

  var EXCLUED_CLUSTER_OPTIONS = ['annotations', 'labels'];
  var AUTHCHOICES = [{
    label: 'clusterNew.rke.auth.x509',
    value: 'x509'
  }];
  var INGRESSCHOICES = [{
    label: 'clusterNew.rke.ingress.nginx',
    value: 'nginx'
  }, {
    label: 'clusterNew.rke.ingress.none',
    value: 'none'
  }];
  var AVAILABLE_STRATEGIES = ['local', 's3'];
  var CLUSTER_TEMPLATE_IGNORED_OVERRIDES = _constants.default.CLUSTER_TEMPLATE_IGNORED_OVERRIDES,
      DEFAULT_BACKEND_TYPE = _constants.default.NETWORK_CONFIG_DEFAULTS.DEFAULT_BACKEND_TYPE;

  var _default = _component.default.extend(_manageLabels.default, _clusterDriver.default, {
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    clusterTemplates: Ember.inject.service(),
    access: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    authChoices: AUTHCHOICES,
    ingressChoices: INGRESSCHOICES,
    availableStrategies: AVAILABLE_STRATEGIES,
    ingornedRkeOverrides: CLUSTER_TEMPLATE_IGNORED_OVERRIDES,
    configField: 'rancherKubernetesEngineConfig',
    registry: 'default',
    accept: '.yml, .yaml',
    backupStrategy: 'local',
    overrideCreatLabel: null,
    loading: false,
    pasteOrUpload: false,
    model: null,
    initialVersion: null,
    registryUrl: null,
    registryUser: null,
    registryPass: null,
    clusterOptErrors: null,
    nodeNameErrors: null,
    existingNodes: null,
    initialNodeCounts: null,
    step: 1,
    token: null,
    taints: null,
    labels: null,
    etcd: false,
    controlplane: false,
    worker: true,
    defaultDockerRootDir: null,
    nodePoolErrors: null,
    windowsEnable: false,
    isLinux: true,
    weaveCustomPassword: false,
    clusterTemplateCreate: false,
    clusterTemplateQuestions: null,
    forceExpandOnInit: false,
    forceExpandAll: false,
    applyClusterTemplate: null,
    useClusterTemplate: false,
    clusterTemplatesEnforced: false,
    selectedClusterTemplateId: null,
    clusterTemplateRevisionId: null,
    isNew: Ember.computed.equal('mode', 'new'),
    isEdit: Ember.computed.equal('mode', 'edit'),
    notView: Ember.computed.or('isNew', 'isEdit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),
    // Custom stuff
    isCustom: Ember.computed.equal('nodeWhich', 'custom'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.initNodeCounts();

      if (!this.useClusterTemplate && this.model.cluster.clusterTemplateRevisionId) {
        Ember.setProperties(this, {
          useClusterTemplate: true,
          forceExpandOnInit: true,
          selectedClusterTemplateId: this.cluster.clusterTemplateId
        });
      }

      if (this.applyClusterTemplate) {
        if (this.useClusterTemplate) {
          this.initTemplateCluster();
        } else {
          this.initNonTemplateCluster();
        }
      } else {
        if (this.model.cluster.clusterTemplateRevisionId && this.router.currentRouteName !== 'global-admin.cluster-templates.new') {
          this.initTemplateCluster();
        } else {
          this.initNonTemplateCluster();

          if (this.clusterTemplateCreate && this.clusterTemplateQuestions && this.clusterTemplateQuestions.length > 0) {
            // cloned
            this.clusterTemplateQuestions.forEach(function (q) {
              _this.send('addOverride', true, {
                path: q.variable
              });
            });
          }
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'isEdit') && !Ember.get(this, 'clusterTemplateCreate')) {
        this.loadToken();
      }

      if (this.applyClusterTemplate) {
        if (this.useClusterTemplate) {
          this.initTemplateCluster();
        } else {
          if (!this.clusterTemplateCreate && Ember.isEmpty(this.cluster.rancherKubernetesEngineConfig)) {
            this.initNonTemplateCluster();
          }
        }
      }

      if (this.useClusterTemplate && this.model.cluster.clusterTemplateRevisionId) {
        Ember.set(this, 'forceExpandAll', true);
      }
    },
    actions: {
      fileUploaded: function fileUploaded() {
        var _this2 = this;

        var yamlValue = this.yamlValue;
        Ember.run.next(function () {
          Ember.set(_this2, 'clusterOptErrors', _this2.checkYamlForRkeConfig(yamlValue));
        });
      },
      yamlValUpdated: function yamlValUpdated(yamlValue, codeMirror) {
        if (!this.codeMirror) {
          codeMirror.on('paste', this.pastedYaml.bind(this));
        } // this fires when we first change over so here is where you can set the paste watcher to validate


        Ember.setProperties(this, {
          yamlValue: yamlValue,
          codeMirror: codeMirror
        });
      },
      addOverride: function addOverride(enabled, paramsToOverride) {
        var hideQuestion = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var primaryResource = this.primaryResource,
            _this$clusterTemplate = this.clusterTemplateQuestions,
            clusterTemplateQuestions = _this$clusterTemplate === void 0 ? [] : _this$clusterTemplate;
        var path = paramsToOverride.path;
        var question = null;
        var questionsSchemas = [];
        var clusterTemplateRevision = this.model.clusterTemplateRevision;

        if (clusterTemplateRevision && (this.clusterTemplates.questionsSchemas || []).length > 0) {
          questionsSchemas = this.clusterTemplates.questionsSchemas;
        }

        if (Ember.isEmpty(clusterTemplateQuestions)) {
          clusterTemplateQuestions = [];
        }

        if (enabled) {
          if (!clusterTemplateQuestions.findBy('variable', path)) {
            if (path.startsWith('uiOverride')) {
              question = questionsSchemas.findBy('variable', path);

              if (!question) {
                question = this.globalStore.createRecord({
                  type: 'question',
                  variable: path,
                  primaryResource: primaryResource
                });
              }

              hideQuestion = true;
              Ember.setProperties(question, {
                type: 'boolean',
                default: true,
                hideQuestion: hideQuestion
              });
              clusterTemplateQuestions.pushObject(question);
            } else {
              question = questionsSchemas.findBy('variable', path);

              if (question) {
                if (question.variable === 'rancherKubernetesEngineConfig.kubernetesVersion') {
                  question = this.parseKubernetesVersionSemVer(primaryResource, question);
                }

                if (question.variable.includes('azureCloudProvider')) {
                  var cloudProviderMatch = _cloudProviderInfo.azure[(question.variable.split('.') || []).lastObject];

                  if (cloudProviderMatch) {
                    var _cloudProviderMatch$r = cloudProviderMatch.required,
                        required = _cloudProviderMatch$r === void 0 ? false : _cloudProviderMatch$r,
                        _cloudProviderMatch$t = cloudProviderMatch.type,
                        type = _cloudProviderMatch$t === void 0 ? 'string' : _cloudProviderMatch$t;
                    question = this.globalStore.createRecord({
                      type: 'question',
                      variable: path
                    });
                    Ember.setProperties(question, {
                      required: required,
                      type: type
                    });

                    if (required) {
                      Ember.set(question, 'forceRequired', true);
                    }
                  }
                }
              } else {
                question = this.globalStore.createRecord({
                  type: 'question',
                  variable: path
                });
                Ember.set(question, 'type', 'string');
              }

              Ember.setProperties(question, {
                primaryResource: primaryResource,
                isBuiltIn: true,
                hideQuestion: hideQuestion
              });

              if (path.includes('rancherKubernetesEngineConfig.privateRegistries[0]')) {
                // need to replace the array target with built in first object so the alias works
                path = path.replace('[0]', '.firstObject');
              }

              Ember.defineProperty(question, 'default', Ember.computed.alias("primaryResource.".concat(path)));
              clusterTemplateQuestions.pushObject(question);
            }
          } else {
            // we've found the template override this wires up the value displayed in overrides section to the form value
            question = clusterTemplateQuestions.findBy('variable', path);
            Ember.setProperties(question, {
              primaryResource: primaryResource,
              isBuiltIn: true,
              hideQuestion: hideQuestion
            });
            Ember.defineProperty(question, 'default', Ember.computed.alias("primaryResource.".concat(path)));
          }
        } else {
          if (path === 'uiOverrideBackupStrategy') {
            question = clusterTemplateQuestions.findBy('variable', 'rancherKubernetesEngineConfig.services.etcd.backupConfig.s3BackupConfig');
            clusterTemplateQuestions.removeObject(question);
          } else {
            question = clusterTemplateQuestions.findBy('variable', path);
            clusterTemplateQuestions.removeObject(question);
          }
        }

        Ember.set(this, 'clusterTemplateQuestions', clusterTemplateQuestions);
      },
      setCpFields: function setCpFields(configName, cloudProviderConfig) {
        Ember.set(this, "config.cloudProvider.".concat(configName), cloudProviderConfig);
      },
      cancel: function cancel() {
        var newOpts = this.getOptsFromYaml();

        if (newOpts) {
          if (this.updateFromYaml && this.mode !== 'view') {
            this.updateFromYaml(newOpts);
            Ember.setProperties(this, {
              clusterOptErrors: [],
              pasteOrUpload: false
            });
          }
        } else {
          if (Ember.isEmpty(this.clusterOptErrors)) {
            Ember.set(this, 'pasteOrUpload', false);
          }
        }
      },
      showPaste: function showPaste() {
        if (!this.configBeforeYaml) {
          // need to store off a raw non-referneced version of the og config
          // if a user switches back to form view and then comes back to yaml to
          // move something to a lower indent or remove we want to only add whats in
          // the orignal config and waht is in the yaml.
          // add the key bloop: true to yaml, sswitch to form, wait no i want bloop nested under something else, comeback and move it, now you have two keys, one at root and dupe at new nest
          Ember.set(this, 'configBeforeYaml', this.primaryResource.clone());
        }

        Ember.set(this, 'pasteOrUpload', true);
      },
      addRegistry: function addRegistry(pr) {
        var config = Ember.get(this, 'config');

        if ((config.privateRegistries || []).length <= 0) {
          Ember.set(config, 'privateRegistries', [pr]);
        } else {
          config.privateRegistries.pushObject(pr);
        }
      },
      removeRegistry: function removeRegistry(pr) {
        Ember.get(this, 'config.privateRegistries').removeObject(pr);
      },
      setTaints: function setTaints(taints) {
        Ember.set(this, 'taints', taints);
      }
    },
    usingClusterTemplate: Ember.observer('useClusterTemplate', function () {
      if (!this.useClusterTemplate && this.model.cluster.clusterTemplateRevisionId) {
        Ember.set(this, 'clusterTemplateRevisionId', null);
      }
    }),
    driverDidChange: Ember.observer('nodeWhich', function () {
      this.createRkeConfigWithDefaults();
    }),
    isLinuxChanged: Ember.observer('isLinux', function () {
      if (Ember.get(this, 'nodeWhich') !== 'custom') {
        return;
      }

      var isLinux = Ember.get(this, 'isLinux');

      if (!isLinux) {
        Ember.setProperties(this, {
          controlplane: false,
          etcd: false,
          worker: true
        });
      }
    }),
    strategyChanged: Ember.observer('backupStrategy', function () {
      var backupStrategy = this.backupStrategy,
          globalStore = this.globalStore;
      var services = this.config.services.clone();

      switch (backupStrategy) {
        case 'local':
          if (services) {
            Ember.setProperties(services.etcd, {
              backupConfig: globalStore.createRecord({
                type: 'backupConfig',
                s3BackupConfig: null,
                enabled: Ember.get(services, 'etcd.backupConfig.enabled') || true
              })
            });
          }

          break;

        case 's3':
          if (services) {
            Ember.setProperties(services.etcd, {
              backupConfig: globalStore.createRecord({
                type: 'backupConfig',
                s3BackupConfig: globalStore.createRecord({
                  type: 's3BackupConfig'
                }),
                enabled: Ember.get(services, 'etcd.backupConfig.enabled') || true
              })
            });
          }

          break;

        default:
          break;
      }

      Ember.set(this, 'config.services', services);
    }),
    enforcementChanged: Ember.on('init', Ember.observer('settings.clusterTemplateEnforcement', function () {
      var _this$access$me$hasAd = this.access.me.hasAdmin,
          globalAdmin = _this$access$me$hasAd === void 0 ? null : _this$access$me$hasAd,
          _this$settings$cluste = this.settings.clusterTemplateEnforcement,
          clusterTemplateEnforcement = _this$settings$cluste === void 0 ? false : _this$settings$cluste;
      var useClusterTemplate = false;

      if (!globalAdmin) {
        // setting is string value
        if (clusterTemplateEnforcement === 'true') {
          clusterTemplateEnforcement = true;
        } else {
          clusterTemplateEnforcement = false;
        }

        if (this.applyClusterTemplate) {
          if (clusterTemplateEnforcement) {
            useClusterTemplate = true;
          } else if (this.model.cluster.clusterTemplateRevisionId) {
            useClusterTemplate = true;
          }
        } else if (!this.applyClusterTemplate && clusterTemplateEnforcement) {
          useClusterTemplate = true;
        } else {
          if (this.model.cluster.clusterTemplateRevisionId) {
            useClusterTemplate = true;
          }
        }

        Ember.setProperties(this, {
          useClusterTemplate: useClusterTemplate,
          clusterTemplatesEnforced: clusterTemplateEnforcement
        });
      }
    })),
    showUpgradeK8sWarning: Ember.computed('selectedClusterTemplateId', 'applyClusterTemplate', 'initialVersion', 'model.clusterTemplateRevision.clusterConfig.rancherKubernetesEngineConfig.kubernetesVersion', function () {
      var applyClusterTemplate = this.applyClusterTemplate,
          initialVersion = this.initialVersion,
          isEdit = this.isEdit;
      var ctrK8sVersion = Ember.get(this, 'model.clusterTemplateRevision.clusterConfig.rancherKubernetesEngineConfig.kubernetesVersion');

      try {
        if (isEdit && applyClusterTemplate) {
          if (initialVersion && ctrK8sVersion && _semver.default.lt(initialVersion, ctrK8sVersion)) {
            return true;
          }
        }
      } catch (err) {
        return false;
      }

      return false;
    }),
    filteredClusterTemplates: Ember.computed('model.clusterTemplates.@each.{id,state,name,members}', function () {
      var clusterTemplates = this.model.clusterTemplates;
      var mapped = clusterTemplates.map(function (clusterTemplate) {
        return {
          name: clusterTemplate.name,
          id: clusterTemplate.id
        };
      });
      return mapped.sortBy('created').reverse();
    }),
    filteredTemplateRevisions: Ember.computed('selectedClusterTemplateId', 'model.clusterTemplateRevisions.@each.{id,state,name,members}', function () {
      var _this3 = this;

      var selectedClusterTemplateId = this.selectedClusterTemplateId,
          _this$clusterTemplate2 = this.clusterTemplateRevisionId,
          clusterTemplateRevisionId = _this$clusterTemplate2 === void 0 ? null : _this$clusterTemplate2,
          _this$model = this.model,
          clusterTemplateRevisions = _this$model.clusterTemplateRevisions,
          clusterTemplates = _this$model.clusterTemplates,
          originalCluster = this.originalCluster,
          intl = this.intl;
      var clusterTemplate;
      clusterTemplateRevisions = clusterTemplateRevisions.slice().filterBy('enabled').filterBy('clusterTemplateId', selectedClusterTemplateId);
      clusterTemplate = clusterTemplates.findBy('id', selectedClusterTemplateId);
      var mapped = clusterTemplateRevisions.map(function (clusterTemplateRevision) {
        var d = (0, _moment.default)(clusterTemplateRevision.created);
        var isDefault = clusterTemplate.defaultRevisionId === clusterTemplateRevision.id;
        var out = {
          id: clusterTemplateRevision.id,
          name: intl.t("clusterNew.rke.clustersSelectTemplateRevision.select.".concat(isDefault ? 'default' : 'other'), {
            name: clusterTemplateRevision.name,
            ago: d.fromNow()
          })
        }; // editing a cluster not a template

        if (_this3.isEdit && _this3.cluster.type === 'cluster') {
          var originalKubeVersion = (0, _parseVersion.coerceVersion)(Ember.get(originalCluster, 'rancherKubernetesEngineConfig.kubernetesVersion'));
          var rawRevisionKubeVersion = clusterTemplateRevision.clusterConfig.rancherKubernetesEngineConfig.kubernetesVersion;
          var revisionKubeVersion = (0, _parseVersion.coerceVersion)(rawRevisionKubeVersion);

          var validRange = rawRevisionKubeVersion.endsWith('.x') && _semver.default.validRange("<=".concat(rawRevisionKubeVersion)); // Filter revisions with kube versions that are downgrades from the original kube version. We're using satisfies here so
          // that we can support semvers with a .x suffix. When the revision kube version is coerced and ends in .x (which signifies latest patch)
          // it gets coerced into .0 which makes the simple lt check erroenously think the revision version is less than the original version. We
          // still use lt just incase the rawRevisionKubeVersion isn't a valid range and needs to be coereced.


          var isRevisionKubeVersionCompatible = validRange ? _semver.default.satisfies(originalKubeVersion, validRange) : _semver.default.lte(originalKubeVersion, revisionKubeVersion);

          if (!isRevisionKubeVersionCompatible) {
            Ember.set(out, 'disabled', true);
          }
        }

        return out;
      });

      if (clusterTemplate && clusterTemplateRevisionId === null) {
        Ember.run.once(function () {
          Ember.set(_this3, 'clusterTemplateRevisionId', clusterTemplate.defaultRevisionId);
        });
      }

      return mapped.sortBy('created').reverse();
    }),
    allTemplates: Ember.computed('model.clusterTemplates.[]', 'model.clusterTemplateRevisions.[]', function () {
      var remapped = [];
      var _this$model2 = this.model,
          clusterTemplates = _this$model2.clusterTemplates,
          clusterTemplateRevisions = _this$model2.clusterTemplateRevisions;
      clusterTemplateRevisions = clusterTemplateRevisions.filterBy('enabled');
      clusterTemplateRevisions.forEach(function (rev) {
        var match = clusterTemplates.findBy('id', Ember.get(rev, 'clusterTemplateId'));

        if (match) {
          remapped.pushObject({
            clusterTemplateId: Ember.get(match, 'id'),
            clusterTemplateName: Ember.get(match, 'displayName'),
            clusterTemplateRevisionId: Ember.get(rev, 'id'),
            clusterTemplateRevisionName: Ember.get(rev, 'name')
          });
        }
      });
      return remapped;
    }),
    canEditForm: Ember.computed('clusterOptErrors.[]', function () {
      return (this.clusterOptErrors || []).length === 0;
    }),
    kubeApiPodSecurityPolicy: Ember.computed('config.services.kubeApi.podSecurityPolicy', {
      get: function get() {
        var pspConfig = Ember.get(this, 'config.services.kubeApi');

        if (typeof pspConfig === 'undefined') {
          return false;
        }

        return Ember.get(pspConfig, 'podSecurityPolicy');
      },
      set: function set(key, value) {
        if (typeof Ember.get(this, 'config.services') === 'undefined') {
          Ember.set(this, 'config.services', Ember.get(this, 'globalStore').createRecord({
            type: 'rkeConfigServices',
            kubeApi: Ember.get(this, 'globalStore').createRecord({
              type: 'kubeAPIService',
              podSecurityPolicy: value
            })
          }));
        } else {
          Ember.set(this, 'config.services', {
            kubeApi: {
              podSecurityPolicy: value
            }
          });
        }

        return value;
      }
    }),
    monitoringProvider: Ember.computed('config.monitoring', {
      get: function get() {
        var monitoringConfig = Ember.get(this, 'config.monitoring');

        if (typeof monitoringConfig === 'undefined') {
          return null;
        }

        return Ember.get(monitoringConfig, 'provider');
      },
      set: function set(key, value) {
        if (typeof Ember.get(this, 'config.monitoring') === 'undefined') {
          Ember.set(this, 'config.monitoring', Ember.get(this, 'globalStore').createRecord({
            type: 'monitoringConfig',
            provider: value
          }));
        } else {
          Ember.set(this, 'config.monitoring', {
            provider: value
          });
        }

        return value;
      }
    }),
    nginxIngressProvider: Ember.computed('config.ingress', {
      get: function get() {
        var ingressConfig = Ember.get(this, 'config.ingress');

        if (typeof ingressConfig === 'undefined') {
          return null;
        }

        return Ember.get(ingressConfig, 'provider');
      },
      set: function set(key, value) {
        if (typeof Ember.get(this, 'config.ingress') === 'undefined') {
          Ember.set(this, 'config.ingress', Ember.get(this, 'globalStore').createRecord({
            type: 'ingressConfig',
            provider: value
          }));
        } else {
          Ember.set(this, 'config.ingress', {
            provider: value
          });
        }

        return value;
      }
    }),
    versionChoices: Ember.computed("settings.".concat(_constants.default.SETTING.VERSIONS_K8S), function () {
      var out = JSON.parse(Ember.get(this, "settings.".concat(_constants.default.SETTING.VERSIONS_K8S)) || '{}');
      out = Object.keys(out);
      var patchedOut = [];

      if (this.clusterTemplateCreate) {
        patchedOut = out.map(function (version) {
          return "".concat((0, _semver.major)(version), ".").concat((0, _semver.minor)(version), ".x");
        });
      }

      return [].concat(_toConsumableArray(out), _toConsumableArray(patchedOut));
    }),
    isNodeNameValid: Ember.computed('nodeName', function () {
      var nodeName = (Ember.get(this, 'nodeName') || '').toLowerCase();

      if (Ember.get(nodeName, 'length') === 0) {
        return true;
      } else {
        var errors = (0, _validate.validateHostname)(nodeName, 'Node Name', Ember.get(this, 'intl'), {
          restricted: true
        });
        Ember.set(this, 'nodeNameErrors', errors);
        return errors.length === 0;
      }
    }),
    isAddressValid: Ember.computed('address', function () {
      return Ember.get(this, 'address') === undefined || Ember.get(this, 'address.length') === 0 || (0, _util.validateEndpoint)(Ember.get(this, 'address'));
    }),
    isInternalAddressValid: Ember.computed('internalAddress', function () {
      return Ember.get(this, 'internalAddress') === undefined || Ember.get(this, 'internalAddress.length') === 0 || (0, _util.validateEndpoint)(Ember.get(this, 'internalAddress'));
    }),
    newNodeCount: Ember.computed('initialNodeCounts', 'primaryResource.id', 'existingNodes.@each.clusterId', function () {
      var clusterId = Ember.get(this, 'primaryResource.id');
      var orig = Ember.get(this, 'initialNodeCounts')[clusterId] || 0;
      var cur = Ember.get(this, 'existingNodes').filterBy('clusterId', clusterId).length;

      if (cur < orig) {
        orig = cur;
        Ember.set(Ember.get(this, 'initialNodeCounts'), clusterId, cur);
      }

      return cur - orig;
    }),
    command: Ember.computed('taints', 'labels', 'token.nodeCommand', 'token.windowsNodeCommand', 'etcd', 'controlplane', 'worker', 'address', 'internalAddress', 'nodeName', 'isLinux', function () {
      var out = Ember.get(this, 'token.nodeCommand');

      if (!out) {
        return;
      }

      var address = Ember.get(this, 'address');
      var nodeName = Ember.get(this, 'nodeName');
      var internalAddress = Ember.get(this, 'internalAddress');
      var roles = ['etcd', 'controlplane', 'worker'];
      var labels = Ember.get(this, 'labels') || {};
      var taints = Ember.get(this, 'taints') || [];
      var windowsSelected = !Ember.get(this, 'isLinux');
      var windowsCmdPostfix = ' | iex}"';

      if (windowsSelected) {
        out = (Ember.get(this, 'token.windowsNodeCommand') || '').replace('--isolation hyperv ', '').replace(windowsCmdPostfix, '');
      }

      if (nodeName) {
        out += " --node-name ".concat(nodeName.toLowerCase());
      }

      if (address) {
        out += " --address ".concat(address);
      }

      if (internalAddress) {
        out += " --internal-address ".concat(internalAddress);
      }

      for (var i = 0, k; i < roles.length; i++) {
        k = roles[i];

        if (Ember.get(this, k)) {
          out += " --".concat(k);
        }
      }

      Object.keys(labels).forEach(function (key) {
        out += " --label ".concat(key, "=").concat(labels[key]);
      });
      taints.forEach(function (taint) {
        out += " --taints ".concat(Ember.get(taint, 'key'), "=").concat(Ember.get(taint, 'value'), ":").concat(Ember.get(taint, 'effect'));
      });

      if (windowsSelected) {
        out += windowsCmdPostfix;
      }

      return out;
    }),
    yamlValue: Ember.computed('pasteOrUpload', {
      get: function get() {
        // On edit we should get the cluster fields that are updateable, any fields added during the creation would need the cluster fields at the time
        var intl = Ember.get(this, 'intl');
        var config = this.isEdit ? this.getSupportedFields(Ember.get(this, 'primaryResource'), 'cluster', EXCLUED_CLUSTER_OPTIONS) : this.getClusterFields(this.primaryResource);
        var cpConfig = Ember.get(config, 'rancher_kubernetes_engine_config.cloud_provider'); // debugger;
        // some kind of recursion bug

        config = (0, _util.removeEmpty)(config, EXCLUDED_KEYS, EXCLUDED_CHILDREN_KEYS); // get rid of undefined

        config = JSON.parse(JSON.stringify(config));

        if (cpConfig) {
          cpConfig = (0, _util.removeEmpty)(cpConfig, EXCLUDED_KEYS, EXCLUDED_CHILDREN_KEYS);

          if (cpConfig.name === 'aws' && !cpConfig.awsCloudProvider) {
            config.rancher_kubernetes_engine_config.cloud_provider = _objectSpread({}, config.rancher_kubernetes_engine_config.cloud_provider, {
              type: 'cloudProvider',
              awsCloudProvider: {
                type: 'awsCloudProvider'
              }
            });
          } else if (cpConfig.azureCloudProvider) {
            // this is a quick and dirty fix for azure only because it is currently the only cp that works
            // this whole process will be recieving updates shortly so this is a temp fix
            // client_id, secret, & subscription_id are all required so ensure they are there on NEW when a user has entered them but switched to yaml or edit
            // removeEmpty works great except for these fields and adding nested paths doesn't work in removeEmpty
            Object.assign(config.rancher_kubernetes_engine_config.cloud_provider.azureCloudProvider, {
              'aad_client_cert_password': cpConfig.azureCloudProvider.aad_client_cert_password || '',
              'aad_client_id': cpConfig.azureCloudProvider.aad_client_id || '',
              'aad_client_secret': cpConfig.azureCloudProvider.aad_client_secret || '',
              'tenant_id': cpConfig.azureCloudProvider.tenant_id || '',
              'subscription_id': cpConfig.azureCloudProvider.subscription_id || ''
            });
          }
        }

        var yaml = _jsYaml.default.safeDump(config, {
          sortKeys: true
        });

        var lines = yaml.split('\n');
        var out = '';
        lines.forEach(function (line, idx) {
          if (line.trim()) {
            var key = '';
            var commentLines = '';

            if (idx === 0) {
              commentLines = intl.t('').split('\n');
              key = "rkeConfigComment.clusterConfig";
            } else {
              key = "rkeConfigComment.".concat(line.split(':')[0].trim());
            }

            if (intl.exists(key)) {
              commentLines = intl.t(key).split('\n');
              commentLines.forEach(function (commentLine) {
                out += "# ".concat(commentLine.slice(1, commentLine.length - 1), "\n");
              });
            }

            out += "".concat(line.trimEnd(), "\n");
          }
        });
        return out;
      },
      set: function set(key, value) {
        var _this4 = this;

        Ember.run.next(function () {
          Ember.set(_this4, 'clusterOptErrors', _this4.checkYamlForRkeConfig(value));
        });
        return value;
      }
    }),
    allErrors: Ember.computed('errors.[]', 'clusterErrors.[]', 'otherErrors.[]', 'clusterOptErrors.[]', function () {
      var errors = this.errors,
          clusterErrors = this.clusterErrors,
          clusterOptErrors = this.clusterOptErrors,
          otherErrors = this.otherErrors;
      return [].concat(_toConsumableArray(errors || []), _toConsumableArray(clusterErrors || []), _toConsumableArray(clusterOptErrors || []), _toConsumableArray(otherErrors || []));
    }),
    getClusterFields: function getClusterFields(primaryResource) {
      var pojoPr = JSON.parse(JSON.stringify((0, _util.removeEmpty)(primaryResource, EXCLUDED_KEYS, EXCLUDED_CHILDREN_KEYS)));
      var decamelizedObj = {};
      decamelizedObj = (0, _util.keysToDecamelize)(pojoPr, void 0, ['type', 'azureCloudProvider']);
      return decamelizedObj;
    },
    checkYamlForRkeConfig: function checkYamlForRkeConfig(yamlValue) {
      var decamledYaml = this.parseOptsFromYaml(yamlValue);
      var errOut = null;

      if (decamledYaml && Ember.isEmpty(decamledYaml.rancherKubernetesEngineConfig)) {
        errOut = ["Cluster Options Parse Error: Missing Rancher Kubernetes Engine Config"];
      }

      return errOut;
    },
    pastedYaml: function pastedYaml(cm) {
      var _this5 = this;

      Ember.run.next(function () {
        Ember.set(_this5, 'clusterOptErrors', _this5.checkYamlForRkeConfig(cm.doc.getValue()));
      });
    },
    parseOptsFromYaml: function parseOptsFromYaml(yamlValue) {
      var yamlConfig;

      try {
        yamlConfig = _jsYaml.default.safeLoad(yamlValue);
      } catch (err) {
        Ember.set(this, 'clusterOptErrors', ["Cluster Options Parse Error: ".concat(err.snippet, " - ").concat(err.message)]);
        return;
      }

      return (0, _util.keysToCamel)(yamlConfig);
    },
    getOptsFromYaml: function getOptsFromYaml() {
      var yamlValue = this.yamlValue;
      var decamledYaml = this.parseOptsFromYaml(yamlValue);

      if (decamledYaml && Ember.isEmpty(decamledYaml.rancherKubernetesEngineConfig)) {
        Ember.set(this, 'clusterOptErrors', ["Cluster Options Parse Error: Missing rancher_kubernetes_engine_config key"]);
        return;
      }

      decamledYaml.type = this.primaryResource.type;
      return this.globalStore.createRecord(decamledYaml);
    },
    buildClusterAnswersFromConfig: function buildClusterAnswersFromConfig(cluster, questions) {
      var backupStrategy = this.backupStrategy;
      var answers = {};

      if (questions && questions.length) {
        questions.forEach(function (quest) {
          var match = Ember.get(cluster, quest.variable);

          if (match) {
            answers[quest.variable] = match;
          } else {
            if (quest.variable.includes('s3BackupConfig') && backupStrategy === 'local') {
              // we get into this case when a RKE Template creator lets the user override the backup strategy, and they've changed it to local.
              // if we dont send the answers with nulls the s3backupconfig will be created on the backend from its existence on the RKE Template revision
              answers[quest.variable] = null;
            }
          }
        });
      }

      return answers;
    },
    willSave: function willSave() {
      var applyClusterTemplate = this.applyClusterTemplate,
          cluster = this.cluster,
          field = this.configField;
      var ok = true;
      this.checkKubernetesVersionSemVer();

      if (this.pasteOrUpload && this.mode !== 'view') {
        var newOpts = this.getOptsFromYaml();

        if (newOpts) {
          if (this.updateFromYaml) {
            this.updateFromYaml(newOpts);
          }
        }
      }

      if (Ember.get(cluster, 'localClusterAuthEndpoint')) {
        if (!Ember.get(cluster, 'rancherKubernetesEngineConfig') || Ember.isEmpty(Ember.get(cluster, 'rancherKubernetesEngineConfig'))) {
          delete cluster.localClusterAuthEndpoint;
        }
      }

      Ember.set(this, 'errors', null);
      ok = this.validate();

      if (ok) {
        if (Ember.typeOf(cluster.clearProvidersExcept) === 'function' || applyClusterTemplate && Ember.typeOf(this.buildClusterAnswersFromConfig) === 'function') {
          if (applyClusterTemplate) {
            var questions = Ember.get(this, 'model.clusterTemplateRevision.questions') || [];
            var answers = [];
            var errors = null;
            answers = this.buildClusterAnswersFromConfig(cluster, questions);

            if (questions.length > 0) {
              errors = this.checkRequiredQuestionsHaveAnswers(questions, answers);
            }

            if (Ember.isEmpty(errors)) {
              Ember.set(cluster, 'answers', {
                values: answers
              });
              this.cluster.clearConfigFieldsForClusterTemplate();
            } else {
              Ember.set(this, 'errors', errors);
              ok = false;
            }
          } else {
            cluster.clearProvidersExcept(field);
          }
        }
      }

      return ok;
    },
    checkRequiredQuestionsHaveAnswers: function checkRequiredQuestionsHaveAnswers(questions, answers) {
      var intl = this.intl;
      var required = questions.filterBy('required', true);
      var errors = [];

      if (questions.length > 0 && required.length > 0) {
        required.forEach(function (rq) {
          if (!answers[rq.variable]) {
            errors.push(intl.t('validation.required', {
              key: rq.variable
            }));
          }
        });
      }

      return errors;
    },
    validate: function validate() {
      var _this6 = this;

      var config = this.config,
          intl = this.intl;

      this._super.apply(this, arguments);

      var errors = this.errors || [];

      if (this.clusterTemplateCreate) {
        var revision = this.model.clusterTemplateRevision;

        if (revision) {
          errors.pushObjects(revision.validationErrors());
          var cloudProvider = Ember.get(revision, 'clusterConfig.rancherKubernetesEngineConfig.cloudProvider.name');
          var azureProvider = Ember.get(revision, 'clusterConfig.rancherKubernetesEngineConfig.cloudProvider.azureCloudProvider') || {};

          if (cloudProvider === 'azure') {
            var azureQuestions = (Ember.get(revision, 'questions') || []).map(function (x) {
              return x.variable.replace(/^rancherKubernetesEngineConfig\.cloudProvider\.azureCloudProvider\./, '');
            });
            var requiredFields = Object.keys(_cloudProviderInfo.azure).filter(function (k) {
              return _cloudProviderInfo.azure[k].required;
            });
            requiredFields.forEach(function (key) {
              if (!Ember.get(azureProvider, key) && !azureQuestions.includes(key)) {
                errors.push(intl.t('validation.requiredOrOverride', {
                  key: key
                }));
              }
            });
          }
        }
      } else {
        if (!Ember.get(this, 'isCustom')) {
          errors.pushObjects(Ember.get(this, 'nodePoolErrors'));
        }

        if (Ember.get(config, 'cloudProvider.name') === 'azure' && !this.applyClusterTemplate) {
          Object.keys(_cloudProviderInfo.azure).forEach(function (key) {
            if (Ember.get(_cloudProviderInfo.azure, "".concat(key, ".required")) && !Ember.get(config, "cloudProvider.azureCloudProvider.".concat(key))) {
              if (_this6.isNew || _this6.isEdit && key !== 'aadClientSecret') {
                errors.push(intl.t('validation.required', {
                  key: key
                }));
              }
            }
          });
        }
      }

      if (Ember.get(config, 'services.kubeApi.podSecurityPolicy') && !Ember.get(this, 'primaryResource.defaultPodSecurityPolicyTemplateId')) {
        errors.push(intl.t('clusterNew.psp.required'));
      }

      if (Ember.get(this, 'config.services.etcd.snapshot')) {
        errors = this.validateEtcdService(errors);
      }

      if (Ember.get(this, 'primaryResource.localClusterAuthEndpoint.enabled')) {
        errors = this.validateAuthorizedClusterEndpoint(errors);
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },
    validateAuthorizedClusterEndpoint: function validateAuthorizedClusterEndpoint(errors) {
      var _EmberGet = Ember.get(this, 'primaryResource'),
          localClusterAuthEndpoint = _EmberGet.localClusterAuthEndpoint;

      var caCerts = localClusterAuthEndpoint.caCerts,
          fqdn = localClusterAuthEndpoint.fqdn;

      if (caCerts) {
        if (!(0, _util.validateCertWeakly)(caCerts)) {
          errors.push(this.intl.t('newCertificate.errors.cert.invalidFormat'));
        }
      }

      if (fqdn) {
        errors = (0, _validate.validateHostname)(fqdn, 'FQDN', Ember.get(this, 'intl'), {
          restricted: true
        }, errors);
      }

      return errors;
    },
    validateEtcdService: function validateEtcdService(errors) {
      var etcdService = Ember.get(this, 'config.services.etcd') || {};
      var creation = etcdService.creation,
          retention = etcdService.retention;
      var that = this;

      function checkDurationIsValid(duration, type) {
        // exact matching on these inputs
        // patternList = 12h12m12s || 12h12m || 12m12s  || 12h12s || 12h || 12m || 12s
        var patternList = [/^(\d+)(h)(\d+)(m)(\d+)(s)$/, /^(\d+)(h)(\d+)(m)$/, /^(\d+)(m)(\d+)(s)$/, /^(\d+)(h)(\d+)(s)$/, /^(\d+)(h)$/, /^(\d+)(m)$/, /^(\d+)(s)$/];
        var match = patternList.filter(function (p) {
          return p.test(duration);
        });

        if (match.length === 0) {
          durationError(duration, type);
        }

        return;
      }

      function durationError(entry, type) {
        return errors.push(Ember.get(that, 'intl').t('clusterNew.rke.etcd.error', {
          type: type,
          entry: entry
        }));
      }

      checkDurationIsValid(creation, 'Creation');
      checkDurationIsValid(retention, 'Reteintion');
      return errors;
    },
    doneSaving: function doneSaving(neu) {
      var close = this.close;

      if (Ember.get(this, 'isCustom')) {
        if (Ember.get(this, 'isEdit')) {
          if (close) {
            close(neu);
          }
        } else {
          this.loadToken();
        }
      } else {
        if (close) {
          close(neu);
        }
      }

      return Ember.RSVP.resolve();
    },
    loadToken: function loadToken() {
      var _this7 = this;

      var cluster = Ember.get(this, 'primaryResource');

      if (cluster.getOrCreateToken) {
        Ember.setProperties(this, {
          step: 2,
          loading: true
        });
        return cluster.getOrCreateToken().then(function (token) {
          if (_this7.isDestroyed || _this7.isDestroying) {
            return;
          }

          Ember.setProperties(_this7, {
            token: token,
            loading: false
          });
        }).catch(function (err) {
          if (_this7.isDestroyed || _this7.isDestroying) {
            return;
          }

          Ember.get(_this7, 'growl').fromError('Error getting command', err);
          Ember.set(_this7, 'loading', false);
        });
      } else {
        return;
      }
    },
    findExcludedKeys: function findExcludedKeys(resourceFields) {
      var _this8 = this;

      Object.keys(resourceFields).forEach(function (key) {
        var type = resourceFields[key].type;

        if (type.startsWith('map[')) {
          var t = type.slice(4, type.length - 1);
          var s = Ember.get(_this8, 'globalStore').getById('schema', t.toLowerCase());

          if (s) {
            var underlineKey = camelToUnderline(key);

            if (EXCLUDED_KEYS.indexOf(underlineKey) === -1) {
              EXCLUDED_KEYS.push(underlineKey);
            }
          }
        }
      });
    },
    getResourceFields: function getResourceFields(type) {
      var schema = Ember.get(this, 'globalStore').getById('schema', type.toLowerCase());
      var resourceFields = null;

      if (schema) {
        resourceFields = Ember.get(schema, 'resourceFields');
        this.findExcludedKeys(resourceFields);
      }

      return resourceFields;
    },
    getFieldValue: function getFieldValue(field, type) {
      var _this9 = this;

      var resourceFields;
      var out = {};

      if (type.startsWith('map[')) {
        type = type.slice(4, type.length - 1);
        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          if (field) {
            Object.keys(field).forEach(function (key) {
              out[camelToUnderline(key)] = _this9.getFieldValue(field[key], type);
            });
            return out;
          } else {
            return null;
          }
        } else {
          if (field) {
            Object.keys(field).forEach(function (key) {
              out[camelToUnderline(key)] = field[key];
            });
            return out;
          } else {
            return null;
          }
        }
      } else if (type.startsWith('array[')) {
        type = type.slice(6, type.length - 1);
        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          return field ? field.map(function (item) {
            return _this9.getFieldValue(item, type);
          }) : null;
        } else {
          return field ? field.map(function (item) {
            return item;
          }) : null;
        }
      } else {
        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          Object.keys(resourceFields).forEach(function (key) {
            if (!Ember.isEmpty(field) && (_typeof(field) !== 'object' || Object.keys(field).length)) {
              out[camelToUnderline(key, type !== 'rkeConfigServices')] = _this9.getFieldValue(field[key], resourceFields[key].type);
            }
          });
          return out;
        } else {
          return field;
        }
      }
    },
    getSupportedFields: function getSupportedFields(source, tragetField) {
      var _this10 = this;

      var excludeFields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var out = {};
      var resourceFields = this.getResourceFields(tragetField);
      Object.keys(resourceFields).filter(function (key) {
        return resourceFields[key].update && excludeFields.indexOf(key) === -1;
      }).forEach(function (key) {
        var field = Ember.get(source, key);
        var type = resourceFields[key].type;

        var value = _this10.getFieldValue(field, type);

        out[camelToUnderline(key)] = value;
      });
      return out;
    },
    initNonTemplateCluster: function initNonTemplateCluster() {
      var _this11 = this;

      if (Ember.get(this, 'isNew')) {
        this.createRkeConfigWithDefaults();
      } else {
        this.initPrivateRegistries();
      }

      this.initBackupConfigs();
      Ember.run.scheduleOnce('afterRender', function () {
        _this11.initRootDockerDirectory();
      });
    },
    initTemplateCluster: function initTemplateCluster() {
      this.initBackupConfigs();
      this.initClusterTemplateQuestions();
    },
    initNodeCounts: function initNodeCounts() {
      var _this12 = this;

      var counts = {};
      var initialVersion = Ember.get(this, 'config.kubernetesVersion');
      Ember.set(this, 'existingNodes', this.globalStore.all('node'));
      this.globalStore.findAll('node').then(function (all) {
        all.forEach(function (node) {
          var id = Ember.get(node, 'clusterId');
          counts[id] = (counts[id] || 0) + 1;
        });

        _this12.notifyPropertyChange('initialNodeCounts');
      });

      if (this.isEdit && !Ember.isEmpty(Ember.get(this, 'cluster.appliedSpec.rancherKubernetesEngineConfig.kubernetesVersion'))) {
        initialVersion = this.cluster.appliedSpec.rancherKubernetesEngineConfig.kubernetesVersion;
      }

      Ember.setProperties(this, {
        initialVersion: initialVersion,
        initialNodeCounts: counts
      });
    },
    initPrivateRegistries: function initPrivateRegistries() {
      var config = Ember.get(this, 'config');

      if (Ember.get(config, 'privateRegistries.length') > 0) {
        var registry = Ember.get(config, 'privateRegistries.firstObject');
        Ember.setProperties(this, {
          registry: 'custom',
          registryUrl: Ember.get(registry, 'url'),
          registryUser: Ember.get(registry, 'user'),
          registryPass: Ember.get(registry, 'password')
        });
      }
    },
    createRkeConfigWithDefaults: function createRkeConfigWithDefaults() {
      var _this13 = this;

      var globalStore = this.globalStore,
          versionChoices = this.versionChoices;
      var defaultVersion = Ember.get(this, "settings.".concat(_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE));
      var satisfying = (0, _parseVersion.maxSatisfying)(versionChoices, defaultVersion);
      var out = {};
      var rkeConfig = globalStore.createRecord({
        type: 'rancherKubernetesEngineConfig',
        ignoreDockerVersion: true,
        kubernetesVersion: satisfying,
        authentication: globalStore.createRecord({
          type: 'authnConfig',
          strategy: 'x509'
        }),
        dns: globalStore.createRecord({
          type: 'dnsConfig',
          nodelocal: globalStore.createRecord({
            type: 'nodelocal',
            ip_address: '',
            node_selector: null,
            update_strategy: {}
          })
        }),
        network: globalStore.createRecord({
          type: 'networkConfig',
          plugin: 'canal',
          options: {
            flannel_backend_type: DEFAULT_BACKEND_TYPE
          }
        }),
        ingress: globalStore.createRecord({
          type: 'ingressConfig',
          provider: 'nginx'
        }),
        monitoring: globalStore.createRecord({
          type: 'monitoringConfig',
          provider: 'metrics-server'
        }),
        services: globalStore.createRecord({
          type: 'rkeConfigServices',
          kubeApi: globalStore.createRecord({
            type: 'kubeAPIService',
            podSecurityPolicy: false,
            serviceNodePortRange: '30000-32767'
          }),
          etcd: globalStore.createRecord({
            type: 'etcdService',
            snapshot: false,
            backupConfig: globalStore.createRecord({
              type: 'backupConfig',
              enabled: true
            }),
            extraArgs: {
              'heartbeat-interval': 500,
              'election-timeout': 5000
            }
          })
        })
      });
      Ember.setProperties(out, {
        rancherKubernetesEngineConfig: rkeConfig,
        enableNetworkPolicy: false
      });

      if (this.isNew) {
        Ember.set(out, 'localClusterAuthEndpoint', globalStore.createRecord({
          type: 'localClusterAuthEndpoint',
          enabled: true
        }));
      }

      Ember.run.scheduleOnce('afterRender', function () {
        Ember.setProperties(_this13.primaryResource, out);

        _this13.notifyPropertyChange('config');
      });
    },
    migrateLegacyEtcdSnapshotSettings: function migrateLegacyEtcdSnapshotSettings() {
      var config = this.config;
      var _config$services$etcd = config.services.etcd,
          retention = _config$services$etcd.retention,
          creation = _config$services$etcd.creation,
          backupConfig = _config$services$etcd.backupConfig;
      var creationMatch = creation.match(/^((\d+)h)?((\d+)m)?((\d+)s)?$/);
      var momentReady = [creationMatch[2], creationMatch[4], creationMatch[6]];

      if (momentReady[1] && momentReady[1] < 30) {
        // round min down since new settting is in intval hours
        momentReady[1] = 0;
      } else if (momentReady[1] && momentReady[1] >= 30) {
        // round up to the nearest hour
        momentReady[0] = parseInt(momentReady[0], 10) + 1;
        momentReady[1] = 0;
      }

      if ((!momentReady[0] || momentReady[0] === 0) && momentReady[1] === 0) {
        // if both hours and min are zero set hour to 1;
        momentReady[0] = 1;
      }

      var toMoment = {
        hours: momentReady[0] ? momentReady[0] : 0,
        minutes: momentReady[1] ? momentReady[1] : 0,
        seconds: momentReady[2] ? momentReady[2] : 0
      };

      var parsedDurationAsHours = _moment.default.duration(toMoment).hours();

      Ember.setProperties(this, {
        legacyRetention: retention,
        hasLegacySnapshotSettings: true
      });

      if (backupConfig) {
        Ember.setProperties(config.services.etcd, {
          'backupConfig.enabled': true,
          'backupConfig.intervalHours': parsedDurationAsHours,
          snapshot: false
        });
      } else {
        backupConfig = this.globalStore.createRecord({
          type: 'backupConfig',
          intervalHours: parsedDurationAsHours,
          enabled: true
        });
        Ember.setProperties(config.services.etcd, {
          backupConfig: backupConfig,
          snapshot: false
        });
      }
    },
    initRootDockerDirectory: function initRootDockerDirectory() {
      Ember.set(this, 'defaultDockerRootDir', Ember.get(this.globalStore.getById('schema', 'cluster').getCreateDefaults(), 'dockerRootDir'));
    },
    initBackupConfigs: function initBackupConfigs() {
      var etcd = Ember.get(this, 'config.services.etcd');

      if (etcd) {
        if (etcd.snapshot) {
          Ember.set(this, 'backupStrategy', 'local');
          this.migrateLegacyEtcdSnapshotSettings();
        } else if (etcd.backupConfig && etcd.backupConfig.s3BackupConfig) {
          Ember.set(this, 'backupStrategy', 's3');
        } else if (!etcd.snapshot && !etcd.backupConfig) {
          var backupConfig = Ember.get(this, 'globalStore').createRecord({
            enabled: false,
            type: 'backupConfig'
          });
          Ember.set(etcd, 'backupConfig', backupConfig);
        } else {
          Ember.set(this, 'backupStrategy', 'local');
        }
      }
    },
    initClusterTemplateQuestions: function initClusterTemplateQuestions() {
      var clusterTemplateQuestions = this.clusterTemplateQuestions,
          primaryResource = this.primaryResource;

      if (clusterTemplateQuestions && clusterTemplateQuestions.length > 0) {
        clusterTemplateQuestions.forEach(function (question) {
          var path = question.variable;

          if (!question.variable.includes('uiOverride') && question.default) {
            (0, _emberDeepSet.default)(primaryResource, path, question.default);
          }

          Ember.set(question, 'primaryResource', primaryResource);
          Ember.defineProperty(question, 'default', Ember.computed.alias("primaryResource.".concat(path)));
        });
      }
    },
    checkKubernetesVersionSemVer: function checkKubernetesVersionSemVer() {
      var _this$clusterTemplate3 = this.clusterTemplateCreate,
          clusterTemplateCreate = _this$clusterTemplate3 === void 0 ? false : _this$clusterTemplate3,
          kubernetesVersion = this.config.kubernetesVersion,
          _this$model$clusterTe = this.model.clusterTemplateRevision,
          clusterTemplateRevision = _this$model$clusterTe === void 0 ? {} : _this$model$clusterTe;
      var questions = [];

      if (clusterTemplateRevision && clusterTemplateRevision.questions) {
        questions = clusterTemplateRevision.questions;
      }

      var kubernetesVersionOverrideExists = questions.findBy('variable', 'rancherKubernetesEngineConfig.kubernetesVersion');

      if (clusterTemplateCreate) {
        if (kubernetesVersionOverrideExists) {
          if (kubernetesVersionOverrideExists.satisfies) {
            // we have a user defined override and this means we dont need satisfies because it can be anything
            delete kubernetesVersionOverrideExists.satisfies;
          }
        } else {
          if (kubernetesVersion.endsWith('x')) {
            this.send('addOverride', true, {
              path: 'rancherKubernetesEngineConfig.kubernetesVersion'
            }, true);
          }
        }
      }
    },
    parseKubernetesVersionSemVer: function parseKubernetesVersionSemVer(cluster, question) {
      var kubernetesVersion = cluster.rancherKubernetesEngineConfig.kubernetesVersion;

      var coercedVersion = _semver.default.coerce(kubernetesVersion);

      var satisfies = ">=".concat(coercedVersion.major, ".").concat(coercedVersion.minor, ".").concat(coercedVersion.patch, " <").concat(coercedVersion.major, ".").concat(coercedVersion.minor + 1);
      Ember.set(question, 'satisfies', satisfies);
      return question;
    }
  });

  _exports.default = _default;
});