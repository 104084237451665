define("shared/components/input-yaml/component", ["exports", "ui/utils/platform", "shared/components/input-yaml/template", "shared/mixins/throttled-resize", "shared/utils/download-files", "codemirror", "js-yaml"], function (_exports, _platform, _template, _throttledResize, _downloadFiles, _codemirror, _jsYaml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_throttledResize.default, {
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    mode: 'text',
    label: null,
    namePlaceholder: '',
    nameRequired: false,
    name: null,
    value: null,
    placeholder: '',
    accept: 'text/*, .yml, .yaml',
    multiple: false,
    viewportMargin: Infinity,
    autoResize: false,
    resizeFooter: 130,
    minHeight: 50,
    inputName: false,
    canChangeName: true,
    canUpload: true,
    showUploadLabel: true,
    gutters: ['CodeMirror-lint-markers'],
    tagName: ['div'],
    showUpload: true,
    showDownload: true,
    showCopy: false,
    shouldChangeName: true,
    _isEditorVisible: false,
    init: function init() {
      this._super.apply(this, arguments); // needed for code mirror???


      window.jsyaml || (window.jsyaml = _jsYaml.default);
    },
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments); // We're using _isEditorVisible to trigger a refresh in the ivy-codemirror as
      // suggested by https://github.com/IvyApp/ivy-codemirror/issues/2. We do this
      // trigger inside of render to ensure the component is already rendered even
      // after 'loading' has changed.


      Ember.run.next(function () {
        Ember.set(_this, '_isEditorVisible', !Ember.get(_this, 'loading'));
      });
    },
    actions: {
      click: function click() {
        this.$('INPUT[type=file]')[0].click();
      },
      wantsChange: function wantsChange() {
        Ember.set(this, 'shouldChangeName', true);
      },
      download: function download() {
        var yaml = Ember.get(this, 'value');
        var lintError = [];

        _jsYaml.default.safeLoadAll(yaml, function (y) {
          lintError.pushObjects(_codemirror.default.lint.yaml(y));
        });

        if (lintError.length) {
          Ember.set(this, 'errors', [Ember.get(this, 'intl').t('yamlPage.errors')]);
          return;
        }

        (0, _downloadFiles.downloadFile)(Ember.get(this, 'filename'), yaml);
      }
    },
    loadingDidChange: Ember.observer('loading', function () {
      var _this2 = this;

      if (!Ember.get(this, 'loading') && Ember.get(this, 'autoResize')) {
        Ember.run.next(function () {
          _this2.fit();
        });
      }
    }),
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),
    onResize: function onResize() {
      if (Ember.get(this, 'autoResize')) {
        this.fit();
      }
    },
    fit: function fit() {
      if (Ember.get(this, 'autoResize')) {
        var container = this.$('.codemirror-container');

        if (!container) {
          return;
        }

        var position = container.position();

        if (!position) {
          return;
        }

        var desired = this.$(window).height() - position.top - Ember.get(this, 'resizeFooter');
        container.css('max-height', Math.max(Ember.get(this, 'minHeight'), desired));
      }
    },
    // fired on file change
    change: function change(event) {
      var _this3 = this;

      var input = event.target;

      if (!input.files || !input.files.length) {
        return;
      }

      if (Ember.get(this, 'canChangeName')) {
        var firstName = input.files[0].name;

        if (Ember.get(this, 'multiple')) {
          var ext = firstName.replace(/.*\./, '');
          Ember.set(this, 'name', "multiple.".concat(ext));
        } else {
          Ember.set(this, 'name', firstName);
        }

        Ember.set(this, 'shouldChangeName', false);
      }

      var promises = [];
      var file;

      for (var i = 0; i < input.files.length; i++) {
        file = input.files[i];
        promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
          var reader = new FileReader();

          reader.onload = function (res) {
            var out = res.target.result;
            resolve(out);
          };

          reader.onerror = function (err) {
            Ember.get(_this3, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
            reject(err);
          };

          reader.readAsText(file);
        }));
      }

      Ember.RSVP.all(promises).then(function (res) {
        if (_this3.isDestroyed || _this3.isDestroying) {
          return;
        }

        var value = res.join('\n');
        Ember.set(_this3, 'value', value);

        if (value) {
          if (_this3.fileChosen) {
            _this3.fileChosen();
          }
        }
      }).finally(function () {
        input.value = '';
      });
    },
    tabReplacement: function tabReplacement(cm) {
      if (cm.somethingSelected()) {
        var sel = cm.doc.getSelection('\n'); // Indent only if there are multiple lines selected, or if the selection spans a full line

        if (sel.length > 0 && (sel.indexOf('\n') > -1 || sel.length === cm.getLine(cm.getCursor().line).length)) {
          cm.indentSelection('add');
          return;
        }
      }

      if (cm.options.indentWithTabs) {
        cm.execCommand('insertTab');
      } else {
        cm.execCommand('insertSoftTab');
      }
    },
    shiftTabReplacement: function shiftTabReplacement(cm) {
      cm.indentSelection('subtract');
    }
  });

  _exports.default = _default;
});