define("shared/mixins/upgrade-component", ["exports", "ui/utils/constants", "semver"], function (_exports, _constants, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NONE = 'none',
      LOADING = 'loading',
      CURRENT = 'current',
      AVAILABLE = 'available',
      REQUIRED = 'required',
      INPROGRESS = 'inprogress',
      UPGRADED = 'upgraded',
      NOTFOUND = 'notfound',
      ERROR = 'error';
  var INPROGRESS_STATES = ['upgrading', 'canceled-upgrade', 'canceling-rollback', 'canceling-upgrade', 'finishing-upgrade', 'rolling-back'];

  var _default = Ember.Mixin.create({
    model: null,
    upgradeOnly: true,
    intl: Ember.inject.service(),
    catalog: Ember.inject.service(),
    router: Ember.inject.service(),
    growl: Ember.inject.service(),
    upgradeInfo: null,
    upgradeStatus: null,
    latestVersion: null,
    launchRoute: 'catalog-tab.launch',
    init: function init() {
      this._super.apply(this, arguments);

      this.updateStatus();
    },
    color: Ember.computed('upgradeStatus', function () {
      switch (Ember.get(this, 'upgradeStatus')) {
        case NONE:
          return 'none';

        case CURRENT:
        case LOADING:
          return 'bg-default';

        case NOTFOUND:
        case ERROR:
        case INPROGRESS:
          return 'bg-disabled';

        case REQUIRED:
          return 'bg-error';

        case AVAILABLE:
        case UPGRADED:
          return 'bg-warning';
      }
    }),
    currentVersion: Ember.computed('model.externalIdInfo.{version}', 'model.externalId', function () {
      var text = Ember.get(this, 'intl').t('upgradeBtn.version.current');
      var version = Ember.get(this, 'model.externalIdInfo.version');

      if (typeof version === 'string' || typeof version === 'number') {
        return "".concat(text, ": ").concat(Ember.get(this, 'upgradeInfo.version'));
      } else {
        return null;
      }
    }),
    externalIdChanged: Ember.on('init', Ember.observer('model.externalIdInfo.[]', 'model.catalogTemplate', function () {
      this.updateStatus();
    })),
    doUpgrade: function doUpgrade() {
      var status = Ember.get(this, 'upgradeStatus');

      if ([REQUIRED, AVAILABLE, CURRENT].indexOf(status) >= 0) {
        var templateId = Ember.get(this, 'model.externalIdInfo.templateId');
        var versionId = Ember.get(this, 'latestVersion');
        var catalogId = Ember.get(this, 'model.externalIdInfo.catalog');
        Ember.get(this, 'router').transitionTo(this.launchRoute, templateId, {
          queryParams: {
            upgrade: versionId,
            catalog: catalogId,
            namespaceId: Ember.get(this, 'model.targetNamespace'),
            appId: Ember.get(this, 'model.id')
          }
        });
      } else if (status === UPGRADED) {
        Ember.get(this, 'model').send('finishUpgrade');
      }
    },
    updateStatus: function updateStatus() {
      if (!this.model) {
        return;
      }

      var _this$model = this.model,
          state = _this$model.state,
          info = _this$model.externalIdInfo,
          catalogTemplate = _this$model.catalogTemplate;
      var upgradeStatus = this.upgradeStatus,
          latestVersion = this.latestVersion;
      var upgradeVersions = {};
      var allVersions = {};

      if (!info) {
        upgradeStatus = NOTFOUND;
      }

      if (state === 'upgraded') {
        upgradeStatus = UPGRADED;
      }

      if (INPROGRESS_STATES.indexOf(state) >= 0) {
        upgradeStatus = INPROGRESS;
      }

      if (info && _constants.default.EXTERNAL_ID.UPGRADEABLE.indexOf(info.kind) >= 0) {
        upgradeStatus = LOADING;
      } else {
        upgradeStatus = NONE;
      }

      if (catalogTemplate) {
        upgradeVersions = parseUpgradeVersions(Ember.get(catalogTemplate, 'versionLinks'), Ember.get(info, 'version'), Ember.get(catalogTemplate, 'name'), Ember.get(this, 'growl'));

        if (Object.keys(upgradeVersions).length >= 1) {
          upgradeStatus = AVAILABLE;
          latestVersion = Object.keys(upgradeVersions)[Object.keys(upgradeVersions).length - 1];
        } else {
          upgradeStatus = CURRENT;
          latestVersion = Ember.get(info, 'version');
        }
      } else {
        upgradeStatus = NOTFOUND;
        latestVersion = Ember.get(info, 'version');
      } // console.log('upgradeVersions', upgradeVersions);


      function parseUpgradeVersions(allVersions, currentVersion, templateName, growl) {
        var keys = Object.keys(allVersions);
        var upgrades = {};
        keys.forEach(function (k) {
          try {
            var gt = _semver.default.gt(_semver.default.coerce(k), _semver.default.coerce(currentVersion));

            if (gt) {
              upgrades[k] = allVersions[k];
            }
          } catch (err) {
            growl.fromError("Invalid catalog app version in ".concat(templateName), err);
          }
        });
        return upgrades;
      }

      Ember.setProperties(this, {
        allVersions: allVersions,
        upgradeVersions: upgradeVersions,
        upgradeStatus: upgradeStatus,
        latestVersion: latestVersion
      });
      return;
    }
  });

  _exports.default = _default;
});